// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-giraldomac-gatsby-theme-wvumedicine-pages-404-js": () => import("./../../../src/@giraldomac/gatsby-theme-wvumedicine/pages/404.js" /* webpackChunkName: "component---src-giraldomac-gatsby-theme-wvumedicine-pages-404-js" */),
  "component---src-pages-about-us-facilities-js": () => import("./../../../src/pages/about-us/facilities.js" /* webpackChunkName: "component---src-pages-about-us-facilities-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-family-advisory-council-js": () => import("./../../../src/pages/family-advisory-council.js" /* webpackChunkName: "component---src-pages-family-advisory-council-js" */),
  "component---src-pages-for-medical-professionals-index-js": () => import("./../../../src/pages/for-medical-professionals/index.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-index-js" */),
  "component---src-pages-for-medical-professionals-refer-patient-js": () => import("./../../../src/pages/for-medical-professionals/refer-patient.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-refer-patient-js" */),
  "component---src-pages-for-medical-professionals-transfer-patient-js": () => import("./../../../src/pages/for-medical-professionals/transfer-patient.js" /* webpackChunkName: "component---src-pages-for-medical-professionals-transfer-patient-js" */),
  "component---src-pages-giving-fundraise-your-way-create-fundraiser-js": () => import("./../../../src/pages/giving/fundraise-your-way/create-fundraiser.js" /* webpackChunkName: "component---src-pages-giving-fundraise-your-way-create-fundraiser-js" */),
  "component---src-pages-giving-fundraise-your-way-index-js": () => import("./../../../src/pages/giving/fundraise-your-way/index.js" /* webpackChunkName: "component---src-pages-giving-fundraise-your-way-index-js" */),
  "component---src-pages-giving-index-js": () => import("./../../../src/pages/giving/index.js" /* webpackChunkName: "component---src-pages-giving-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outpatient-clinics-index-js": () => import("./../../../src/pages/outpatient-clinics/index.js" /* webpackChunkName: "component---src-pages-outpatient-clinics-index-js" */),
  "component---src-pages-patients-visitors-index-js": () => import("./../../../src/pages/patients-visitors/index.js" /* webpackChunkName: "component---src-pages-patients-visitors-index-js" */),
  "component---src-pages-patients-visitors-mywvuchart-js": () => import("./../../../src/pages/patients-visitors/mywvuchart.js" /* webpackChunkName: "component---src-pages-patients-visitors-mywvuchart-js" */),
  "component---src-pages-patients-visitors-programs-js": () => import("./../../../src/pages/patients-visitors/programs.js" /* webpackChunkName: "component---src-pages-patients-visitors-programs-js" */),
  "component---src-pages-patients-visitors-visitor-resources-js": () => import("./../../../src/pages/patients-visitors/visitor-resources.js" /* webpackChunkName: "component---src-pages-patients-visitors-visitor-resources-js" */),
  "component---src-pages-patients-visitors-your-hospital-stay-js": () => import("./../../../src/pages/patients-visitors/your-hospital-stay.js" /* webpackChunkName: "component---src-pages-patients-visitors-your-hospital-stay-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-primary-care-js": () => import("./../../../src/pages/services/primary-care.js" /* webpackChunkName: "component---src-pages-services-primary-care-js" */),
  "component---src-pages-services-specialty-care-js": () => import("./../../../src/pages/services/specialty-care.js" /* webpackChunkName: "component---src-pages-services-specialty-care-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-center-of-excellence-conditions-js": () => import("./../../../src/templates/center-of-excellence-conditions.js" /* webpackChunkName: "component---src-templates-center-of-excellence-conditions-js" */),
  "component---src-templates-center-of-excellence-js": () => import("./../../../src/templates/center-of-excellence.js" /* webpackChunkName: "component---src-templates-center-of-excellence-js" */),
  "component---src-templates-center-of-excellence-related-blogs-js": () => import("./../../../src/templates/center-of-excellence-related-blogs.js" /* webpackChunkName: "component---src-templates-center-of-excellence-related-blogs-js" */),
  "component---src-templates-center-of-excellence-related-programs-js": () => import("./../../../src/templates/center-of-excellence-related-programs.js" /* webpackChunkName: "component---src-templates-center-of-excellence-related-programs-js" */),
  "component---src-templates-center-of-excellence-related-services-js": () => import("./../../../src/templates/center-of-excellence-related-services.js" /* webpackChunkName: "component---src-templates-center-of-excellence-related-services-js" */),
  "component---src-templates-center-of-excellence-resources-js": () => import("./../../../src/templates/center-of-excellence-resources.js" /* webpackChunkName: "component---src-templates-center-of-excellence-resources-js" */),
  "component---src-templates-children-program-clinic-locations-js": () => import("./../../../src/templates/children-program-clinic-locations.js" /* webpackChunkName: "component---src-templates-children-program-clinic-locations-js" */),
  "component---src-templates-children-program-conditions-js": () => import("./../../../src/templates/children-program-conditions.js" /* webpackChunkName: "component---src-templates-children-program-conditions-js" */),
  "component---src-templates-children-program-diagnostics-js": () => import("./../../../src/templates/children-program-diagnostics.js" /* webpackChunkName: "component---src-templates-children-program-diagnostics-js" */),
  "component---src-templates-children-program-downloads-js": () => import("./../../../src/templates/children-program-downloads.js" /* webpackChunkName: "component---src-templates-children-program-downloads-js" */),
  "component---src-templates-children-program-faqs-js": () => import("./../../../src/templates/children-program-faqs.js" /* webpackChunkName: "component---src-templates-children-program-faqs-js" */),
  "component---src-templates-children-program-js": () => import("./../../../src/templates/children-program.js" /* webpackChunkName: "component---src-templates-children-program-js" */),
  "component---src-templates-children-program-preparing-appointment-js": () => import("./../../../src/templates/children-program-preparing-appointment.js" /* webpackChunkName: "component---src-templates-children-program-preparing-appointment-js" */),
  "component---src-templates-children-program-providers-js": () => import("./../../../src/templates/children-program-providers.js" /* webpackChunkName: "component---src-templates-children-program-providers-js" */),
  "component---src-templates-children-program-services-js": () => import("./../../../src/templates/children-program-services.js" /* webpackChunkName: "component---src-templates-children-program-services-js" */),
  "component---src-templates-children-program-treatments-js": () => import("./../../../src/templates/children-program-treatments.js" /* webpackChunkName: "component---src-templates-children-program-treatments-js" */),
  "component---src-templates-clinic-js": () => import("./../../../src/templates/clinic.js" /* webpackChunkName: "component---src-templates-clinic-js" */),
  "component---src-templates-donor-story-js": () => import("./../../../src/templates/donor-story.js" /* webpackChunkName: "component---src-templates-donor-story-js" */),
  "component---src-templates-fundraise-activity-js": () => import("./../../../src/templates/fundraise-activity.js" /* webpackChunkName: "component---src-templates-fundraise-activity-js" */),
  "component---src-templates-generic-js": () => import("./../../../src/templates/generic.js" /* webpackChunkName: "component---src-templates-generic-js" */),
  "component---src-templates-highlighted-facility-js": () => import("./../../../src/templates/highlighted-facility.js" /* webpackChunkName: "component---src-templates-highlighted-facility-js" */),
  "component---src-templates-leadership-js": () => import("./../../../src/templates/leadership.js" /* webpackChunkName: "component---src-templates-leadership-js" */),
  "component---src-templates-patient-story-js": () => import("./../../../src/templates/patient-story.js" /* webpackChunkName: "component---src-templates-patient-story-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-program-js": () => import("./../../../src/templates/program.js" /* webpackChunkName: "component---src-templates-program-js" */),
  "component---src-templates-provider-js": () => import("./../../../src/templates/provider.js" /* webpackChunkName: "component---src-templates-provider-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-unit-js": () => import("./../../../src/templates/unit.js" /* webpackChunkName: "component---src-templates-unit-js" */),
  "component---src-templates-ways-to-give-js": () => import("./../../../src/templates/ways-to-give.js" /* webpackChunkName: "component---src-templates-ways-to-give-js" */)
}

